<template>
  <v-container fluid>
    <v-row style="position: sticky; top: 64px; z-index: 2">
      <v-col cols="12">
        <PageHeader>
          <span slot="courses" class="font-weight-light">LECTURER </span>
          ASSESSMENT
        </PageHeader>
        <v-card>
          <v-card-text>
            <v-form ref="courseHeader">
              <v-row align="center" justify="center">
                <v-col cols="12" lg="4">
                  <v-select
                    class="rounded-0"
                    :menu-props="{ down: true, offsetY: true }"
                    :loading="iscalendarLoaded"
                    v-model="calendar"
                    :items="basket_calendar"
                    :item-text="
                      (item) =>
                        `${item.academic_calendar} ${
                          item.current ? ' \t      Current' : ''
                        }`
                    "
                    item-value="id"
                    outlined
                    label="Calendar"
                    @click="fetchCalendar"
                    hide-details="auto"
                    :rules="[(v) => !!v || 'Calendar is required...']"
                  >
                    <template v-slot:selection="{ item }">
                      {{ item.academic_calendar }}
                      <b>{{ `${item.current ? "\t  Current" : ""} ` }}</b>
                    </template>
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Calendar(s)...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-select
                    :menu-props="{ down: true, offsetY: true }"
                    :loading="issemesterLoaded"
                    @click="fetchSemester"
                    v-model="semester"
                    class="rounded-0"
                    :items="basket_semester"
                    item-value="id"
                    item-text="semester"
                    outlined
                    label="Semester"
                    hide-details="auto"
                    hide-selected
                    :rules="[(v) => !!v || 'Semester is required...']"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Semesters...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="getters_assessment.length > 0 && semester && calendar">
      <v-col>
        <v-scroll-y-reverse-transition tag="v-data-table" class="py-0">
          <v-data-table
            :headers="headers"
            :items="getters_assessment"
            class="elevation-1"
            item-key="id"
            disable-pagination
            hide-default-footer
          >
            <template #item.evaluation="{ item }">
              <v-btn color="success" icon @click="showAnalysis(item)">
                <v-icon>mdi-arrow-right-box</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-scroll-y-reverse-transition>
      </v-col>
    </v-row>

    <v-navigation-drawer
      clipped
      v-model="analysisBool"
      fixed
      :permanent="analysisBool"
      right
      width="80%"
      class="shadow"
    >
      <v-toolbar class="mt-15" tile color="primary" dark flat>
        <b class="text-uppercase" v-if="evaluationData">
          {{ `EVALUATION ASSESSMENT FOR ${evaluationData.user.title_name}` }}
          <v-icon>mdi-minus</v-icon>
          {{ `${evaluationData.course.coursecode_coursename}` }}
        </b>
        <v-spacer></v-spacer>
        <v-btn class="ma-2" @click="analysisBool = false" icon>
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-toolbar>
      <Analysis v-if="analysisBool" />
    </v-navigation-drawer>

    <v-row>
      <v-col>
        <v-overlay absolute :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-col>
    </v-row>

    <Response v-if="response">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>
<script>
  import PageHeader from "@/components/slots/PageHeader";
  import { provide, reactive, toRefs, watch } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import Response from "@/components/ActionResponse/Response";
  import Analysis from "@/components/QualityAssurance/Analysis";

  export default {
    components: { PageHeader, Response, Analysis },
    setup(/* props, context */) {
      const { getSemester, getCalendar, getLecturerAssessment } = useActions([
        "getSemester",
        "getCalendar",
        "getLecturerAssessment",
      ]);

      const { getters_semester, getters_calendar, getters_assessment } =
        useGetters([
          "getters_semester",
          "getters_calendar",
          "getters_assessment",
        ]);

      const courseHeader = reactive({
        overlay: false,
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        color: "",
        response: false,
        iscalendarLoaded: false,
        issemesterLoaded: false,
        calendar: "",
        semester: "",
        basket_calendar: [],
        basket_semester: [],
        headers: [
          {
            text: "COURSE",
            value: "course.coursecode_coursename",
          },
          { text: "PROGRAM", value: "header.program.program_name_certificate" },
          { text: "LEVEL", value: "header.level.level" },
          { text: "SESSION", value: "session.session" },
          { text: "INTAKE", value: "header.intake.intake" },
          {
            text: "CAMPUS",
            value: "header.campus.campus_name",
          },
          { text: "LECTURER", value: "user.title_name" },
          {
            text: "EVALUATION",
            value: "evaluation",
            sortable: false,
            align: "center",
          },
        ],
        evaluationData: 0,
        analysisBool: false,
      });

      const {
        msgHeader,
        msgBody,
        msgIcon,
        response,
        basket_calendar,
        basket_semester,
        calendar,
        semester,
        iscalendarLoaded,
        issemesterLoaded,
        overlay,
        evaluationData,
        color,
        analysisBool,
      } = toRefs(courseHeader);

      watch(
        () => [calendar.value, semester.value],
        () => {
          if (calendar.value && semester.value) {
            overlay.value = true;
            let header = {
              calendar_id: calendar.value,
              semester_id: semester.value,
            };
            getLecturerAssessment(header)
              .then(() => {
                overlay.value = false;
              })
              .catch(() => {
                response.value = true;
                color.value = "error";
                msgBody.value =
                  "Something went wrong. Try Again Later Or Call The System Administrator";
                msgHeader.value = "Error";
                msgIcon.value = "mdi-close-circle";
              })
              .finally(() => (overlay.value = false));
          }
        }
      );
      provide("color", color);

      const fetchCalendar = () => {
        iscalendarLoaded.value = true;
        if (getters_calendar.value.length > 0) {
          iscalendarLoaded.value = false;
          basket_calendar.value = getters_calendar.value;
          return;
        }
        getCalendar().then(() => {
          basket_calendar.value = getters_calendar.value;
          iscalendarLoaded.value = false;
        });
      };

      const fetchSemester = () => {
        issemesterLoaded.value = true;
        if (getters_semester.value.length > 0) {
          basket_semester.value = getters_semester.value;
          issemesterLoaded.value = false;
          return;
        }

        getSemester().then(() => {
          basket_semester.value = getters_semester.value;
          issemesterLoaded.value = false;
        });
      };

      const showAnalysis = (item) => {
        evaluationData.value = item;
        analysisBool.value = true;
      };

      provide("evaluationData", evaluationData);
      provide("calendarSemester", {
        calendar_id: calendar,
        semester_id: semester,
      });

      return {
        ...toRefs(courseHeader),
        fetchCalendar,
        fetchSemester,
        getters_assessment,
        showAnalysis,
      };
    },
  };
</script>
<style scoped>
  .border:hover {
    border-left: 5px solid #1976d2 !important;
    cursor: pointer !important;
  }
</style>
