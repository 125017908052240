<template>
  <v-container fluid>
    <v-card flat class="pa-0 ma-0">
      <v-expansion-panels focusable inset>
        <v-expansion-panel v-for="(response, i) in graphData" :key="i">
          <v-expansion-panel-header>
            {{ response.question }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="pt-4">
              <bar-chart
                :data="response.data"
                xtitle="Alternatives"
                ytitle="Number of Response"
                :download="true"
              ></bar-chart>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>

    <v-card outlined class="pa-0 mt-4">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header class="primary secondary--text">
            Text Answers
            <template v-slot:actions>
              <v-icon color="secondary"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-4">
            <v-list two-line>
              <v-list-item v-for="(item, i) of textData" :key="i">
                <v-list-item-content>
                  <v-list-item-title>{{ i }}</v-list-item-title>
                  <template v-for="(feedback, index) in item">
                    <v-list-item-subtitle :key="index" class="font-italic">
                      <v-row>
                        <v-col cols="12" lg="1"> {{ index + 1 }} </v-col>
                        <v-col cols="12" lg="11">
                          {{ feedback.response }}
                        </v-col>
                      </v-row>
                    </v-list-item-subtitle>
                  </template>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>

    <Response v-if="response">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>

    <v-overlay absolute :value="overlay" :opacity="0.5">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
      <div class="mt-2 text-center">Loading</div>
    </v-overlay>
  </v-container>
</template>
<script>
  import { defineComponent, inject, toRefs, reactive, provide } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import Response from "@/components/ActionResponse/Response";

  export default defineComponent({
    components: { Response },
    setup() {
      const { getAnalysisData } = useActions(["getAnalysisData"]);
      const { getters_analysis } = useGetters(["getters_analysis"]);

      const evaluationData = inject("evaluationData");
      const calendarSemester = inject("calendarSemester");

      const analysisData = reactive({
        overlay: true,
        radioGroup: null,
        chosen: [],
        observer1: null,
        items: [],
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        color: "",
        response: false,
        data: {},
        graphData: [],
        textData: [],
      });

      const {
        overlay,
        msgHeader,
        msgBody,
        msgIcon,
        response,
        color,
        data,
        graphData,
        textData,
      } = toRefs(analysisData);

      getAnalysisData({
        user_id: evaluationData?.value?.user_id,
        session_id: evaluationData?.value?.session_id,
        course_id: evaluationData?.value?.course_id,
        campus_id: evaluationData?.value?.header?.campus_id,
        program_id: evaluationData?.value?.header?.program_id,
        intake_id: evaluationData?.value?.header?.intake_id,
        level_id: evaluationData?.value?.header?.level_id,
        calendar_id: calendarSemester?.calendar_id?.value,
        semester_id: calendarSemester?.semester_id?.value,
      })
        .then(() => {
          data.value = [...getters_analysis.value]
            .filter((item) => item.evaluation_template.type == "Multiple Choice")
            .map((item) => ({
              question: item.evaluation_template.question,
              response: item.response,
              alternatives: [...item.evaluation_template.alternatives]
                .map((alternative) =>
                  alternative.item == item.response
                    ? {
                        alternative: alternative.item,
                        total: 1,
                      }
                    : {
                        alternative: alternative.item,
                        total: 0,
                      }
                )
                .reduce((acc, curr) => {
                  let key = curr.alternative;

                  if (!acc[key]) acc[key] = 0;
                  acc[key] += curr.total;

                  return acc;
                }, {}),
            }))
            .reduce((acc, curr) => {
              let key = curr.question;
              if (!acc[key]) acc[key] = [];

              acc[key].push(curr.alternatives);
              return acc;
            }, {});

          for (let [key, value] of Object.entries(data.value)) {
            let result = {};
            value.forEach((element) => {
              for (let [key1, value1] of Object.entries(element)) {
                if (result[key1]) {
                  //(4)
                  result[key1] += value1; //(5)
                } else {
                  //(6)
                  result[key1] = value1;
                }
              }
            });
            graphData.value.push({ data: result, question: key });
          }

          textData.value = [...getters_analysis.value].reduce((acc, obj) => {
            if (obj.evaluation_template.type == "Text Answer") {
              let key = obj.evaluation_template.question;
              acc[key] ??= [];
              acc[key].push({ response: obj.response });
            }
            return acc;
          }, {});
        })
        .catch(() => {
          response.value = true;
          color.value = "error";
          msgBody.value =
            "Something went wrong. Try Again Later Or Call The System Administrator";
          msgHeader.value = "Error";
          msgIcon.value = "mdi-close-circle";
        })
        .finally(() => (overlay.value = false));

      provide("color", color);

      return {
        ...toRefs(analysisData),
        data,
        graphData,
      };
    },
  });
</script>
